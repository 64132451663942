/* Container for the showcase */
.showcase-container {
  font-family: Arial, sans-serif;
}

/* Hero Section */
.hero-section {
  background: rgb(133, 141, 133);
  /* url('/path/to/your-background-image.jpg') center/cover no-repeat; */
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: 20px; /* Add padding for small screens */
  position: relative;
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

/* .cta-button {
  background-color: #ff5733;
  color: white;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
} */


.cta-button {
  background-color: #ff5733;
  color: white;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  gap: 10px; /* Space between icon and text */
}

.icons-aling{
  text-align: center;
  /* margin-bottom: 20px; */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  gap: 10px; /* Space between icon and text */
}
.cta-button:hover {
  background-color: #e74c3c;
}

.desktop_view {
  display: block; /* Show desktop-specific elements */
}

.mobile_view {
  display: none; /* Ensure mobile elements are hidden on larger screens */
}

/* Services Section */
.services-section {
  padding: 50px;
}

.services-section h2 {
  text-align: center;
  margin-bottom: 20px;
}

.services-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.service-card {
  display: flex;
  height: auto;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap; /* Enable wrapping for small screens */
}

.service-card img {
  width: 100%;  /* Set the desired width */
  height: 100x; /* Set the desired height */
  object-fit: fill; /* Maintain aspect ratio, cover the entire area */
  border-radius: 10px;
  flex: 1;
}

.service-card-text {
  flex: 1;
}

.service-card.reverse {
  flex-direction: row-reverse;
}

.service-card h3 {
  text-align: center;
  margin-top: 0;
}

/* About Us Section */
.about-us-section {
  padding: 50px;
  background-color: #f4f4f4;
  text-align: center;
}

.about-us-section h2 {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally if needed */
  gap: 10px; /* Space between text and icon */
  font-family: 'Poppins', sans-serif; /* Trendy font */
  font-weight: 600; /* Semi-bold font weight */
  color: #333; /* Modern dark color for text */
  font-size: 2rem; /* Adjust size as needed */
}

.about-us-section h2 svg { /* Style for the icon */
  font-size: 2rem; /* Adjust size to match text */
}
/* CTA Section */
.cta-section {
  padding: 50px;
  text-align: center;
  background-color: #333;
  color: #fff;
}

.cta-link {
  color: #ff5733;
  text-decoration: none;
}

.cta-link:hover {
  text-decoration: underline;
}

.custom-applications-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white; /* or another color if needed */
}

.text-section {
  max-width: 50%;
}

.text-section h4 {
  color: #6BA539; /* or your desired color */
  font-weight: normal;
}

.text-section h1 {
  font-size: 2.5rem;
  margin-top: 10px;
  margin-bottom: 20px;
}

.text-section p {
  font-size: 1rem;
  line-height: 1.5;
}

.image-section img {
  max-width: 100%;
  height: 50%;
}

/* Plane Icon Animation */
.plane-icon {
  font-size: 30px; /* Adjust size as needed */
  margin-bottom: 30px;
  animation: takeoff 5s linear infinite; /* Changed easing to linear */
  position: absolute; /* Absolute positioning for better control */
  left: 500px; /* Adjust starting point if needed */
}






.service-description {
  font-size: 0.7rem; /* Adjust this to your desired font size */
  line-height: 1.5; /* Adjust this to your desired line height */
}

/* this is for privacy and t&c page image  */


.centered-image {
  display: block;          /* Ensures the image behaves as a block element */
  margin-left: auto;       /* Automatically adjusts the left margin */
  margin-right: auto;      /* Automatically adjusts the right margin */
  max-width: 100%;         /* Ensures the image does not exceed the width of its container */
  height: 50%;            /* Maintains the aspect ratio of the image */
  width: 90%;              /* Sets a default width (adjust as needed) */
  border-radius: 10px;     /* Rounds the corners of the image */
}





/* Ensure that the content is hidden initially but remains in the DOM */
.service-description {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

/* When expanded, set a high max-height to make it visible */
.service-description.max-h-full {
  max-height: 500px; /* Adjust this value according to your content */
}

.card-content {
  position: absolute; /* Position the content absolutely */
  top: 100%; /* Position below the image */
  left: 0;
  right: 0;
  opacity: 0;
  transform: translateY(20px); /* Move content down */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Transition for smooth effect */
  pointer-events: none; /* Disable pointer events when not expanded */
}

.card-content.expanded {
  opacity: 1;
  transform: translateY(0); /* Move content into view */
  pointer-events: auto; /* Enable pointer events when expanded */
}

@keyframes takeoff {
  0% {
    transform: translate(0, 0) rotate(0deg) translateZ(0); /* Start position */
  }
  50% {
    transform: translate(150px, -30px) rotate(-15deg) translateZ(0); /* Extended horizontal and upward movement */
  }
  100% {
    transform: translate(300px, -60px) rotate(-30deg) translateZ(0); /* Further extended horizontal and upward movement */
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .hero-section {
    background: rgb(133, 141, 133);
    /* url('/path/to/your-background-image.jpg') center/cover no-repeat; */
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    padding: 20px; /* Add padding for small screens */
    position: relative;
  }


  .hero-section h1 {
    font-size: 2.5rem;
  }
  .showcase-container {
    font-family: Arial, sans-serif;
  }
  .cta-button {
    background-color: #ff5733;
    color: white;
    padding: 10px 20px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .desktop_view {
    display: none; /* Hide desktop-specific elements */
  }
  
  .mobile_view {
    display: block; /* Show mobile-specific elements */
  }

  .services-section {
    padding: 20px;
  }

  .service-card {
    flex-direction: column;
     /* Stack cards vertically on small screens */
     /* width: 100px;  /* Set the desired width */

     
  }

  .service-card.reverse {
    flex-direction: column; /* Stack cards vertically */
  }

  .about-us-section {
    padding: 20px;
  }

  .cta-section {
    padding: 20px;
  }

  .plane-icon {
    left: 20px; /* Adjust starting point for mobile */
    font-size: 20px; /* Adjust size for mobile */
  }
}

@media (max-width: 480px) {
  .hero-section h1 {
    font-size: 2rem;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 6px 12px;
  }
  
  .plane-icon {
    left: 90px; /* Adjust further for very small screens */
    font-size: 18px; /* Adjust size further for very small screens */
  }
}
