/* index.css */

/* Tailwind CSS Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add your custom styles here */
body {
  font-family: 'Arial', sans-serif; /* Example of custom font */
  line-height: 1.6;
  color: #333;
  background-color: #f7f7f7; /* Example of custom background color */
}

/* Custom classes can be defined here */
.custom-button {
  @apply bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700;
}

.custom-header {
  @apply text-4xl font-bold text-center mb-4;
}

/* More custom styles can be added as needed */
