/* Auditing Tool CSS */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

.audit-container {
  max-width: 800px;
  margin: 80px auto;
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
  font-size: 32px;
  margin-bottom: 20px;
}

.audit-form {
  text-align: center;
  margin-bottom: 20px;
}

.audit-form h3 {
  margin-bottom: 10px;
  color: #555;
}

.audit-form input {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 300px;
  margin: 10px auto;
  border: 2px solid #ddd;
  border-radius: 4px;
}

.audit-form button {
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
  padding: 12px;
  font-size: 18px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.audit-form button:hover {
  background-color: #45a049;
}

.audit-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.audit-results {
  margin-top: 20px;
  text-align: center;
}

.results-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

.result-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  transition: transform 0.2s;
}

.result-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.result-summary {
  font-size: 14px;
  color: #555;
}

.result-card h4 {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.result-details {
  font-size: 14px;
  margin-top: 10px;
  color: #444;
}

button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
