/* ShopByCategory.css */

.shop-by-category {
  background: linear-gradient(to right, #a8e6cf, #ff8b94);
  padding: 50px 20px;
  text-align: center;
}

.main-heading {
  font-family: 'Arial', sans-serif;
  color: #000000;
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.category-cards-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.category-card {
  position: relative;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  width: 100%;
  max-width: 300px;
  height: auto;
  padding: 20px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1), -5px -5px 15px rgba(255, 255, 255, 0.8);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
}

.category-card:hover {
  transform: translateY(-10px);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.2), -10px -10px 30px rgba(255, 255, 255, 0.9);
}

.category-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-top-right-radius: 50px;
}

.category-card:hover .category-image {
  transform: scale(1.05);
}

.category-title {
  font-family: 'Arial', sans-serif;
  color: #333;
  font-size: 1.5rem;
  margin: 15px 0 10px;
  text-transform: uppercase;
  font-weight: bold;
}

.category-description {
  font-family: 'Arial', sans-serif;
  color: #666;
  font-size: 1rem;
  text-align: center;
}

.services-section {
  padding: 50px;
}

.services-section h2 {
  text-align: center;
  margin-bottom: 20px;
}

.services-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.service-card {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  flex-direction: column;
}

.service-card img {
  width: 100%;  
  max-width: 300px;  
  height: auto; 
  object-fit: cover; 
  border-radius: 10px;
}

.service-card-text {
  text-align: center;
}

.service-card.reverse {
  flex-direction: column-reverse;
}

.service-card h3 {
  text-align: center;
  margin-top: 0;
}

/* Media Queries for Responsiveness */
@media (min-width: 768px) {
  .category-cards-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .category-card {
    width: calc(50% - 20px);
  }
}

@media (min-width: 1024px) {
  .category-card {
    width: calc(33.333% - 20px); /* 3 cards per row */
  }

  .service-card {
    flex-direction: row;
  }

  .service-card.reverse {
    flex-direction: row-reverse;
  }
}

@media (max-width: 768px) {
  .category-cards-container,
  .services-grid {
    flex-direction: column;
  }

  .category-card,
  .service-card {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .main-heading {
    font-size: 2rem;
  }

  .category-title {
    font-size: 1.2rem;
  }

  .category-description {
    font-size: 0.9rem;
  }
}
